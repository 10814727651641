<template>
    <div class="addWarnInsect">
        <div class="warn_tools_box">
            <el-input placeholder="请输入虫害名" v-model="insectFromInfo.name" class="search_input" size="small"></el-input>
            <el-button type="primary" @click="toSearch" size="small">搜索</el-button>
            <el-button type="warning" @click="toReset" size="small" style="color: #fff;">重置</el-button>
        </div>
        <div class="warn_content_box scrollbarMiniGary">
            <div v-for="item in insectInfo" :key="item.id">
                <div>
                    <el-checkbox v-model="item.check" @change="toCheck(item)"></el-checkbox>
                    <span class="single-ellipsis text-lable">{{ item.name }}</span>
                </div>
            </div>
        </div>
        <div class="pages_box">
            <el-pagination @current-change="handleCurrentChange" :current-page.sync="insectFromInfo.pageIndex"
                :page-size="insectFromInfo.pageSize" layout="total, prev, pager, next" class="pageInfo" background :total="picTotal">
            </el-pagination>
        </div>
    </div>
</template>

<script>

    export default {
        data(){
            return {
                search: '',
                insectInfo: [],
                insectFromInfo: {
       
                    pageSize: 45,
                    pageIndex: 1,
                    name: ''
                
                },
                picTotal: 0,
                checkArr: []
            }
        },
        created(){
            this.getList()
        },
        methods:{
            submit(dialog, cb){
               console.log(this.checkArr)
                if(this.checkArr.length > 0){
                    const reqData = this.checkArr.map(item => {
                        return {
                            insectLibId: item,
                            alarmMax: 50
                        }
                    })
                    this.$api.AddIotInsectAlarm(reqData).then(res => {
                        if(res){
                            this.$msg.success("设置成功")
                            dialog.close()
                            cb()
                        }
                    })
                }else{
                    this.$msg.error("请选择需要添加的虫害");
                }
            },
            async getList(){
                const res = await this.$api.IotInsectLibCompanyPageList(this.insectFromInfo)
                let list = res ?.list || []
                list.map(item => {
                    if(this.checkArr.includes(item.id)){
                        item.check = true
                    }else{
                        item.check = false
                    }
                })
                this.insectInfo = list
                this.picTotal = res.totalCount
            },
            toSearch(){
                this.$set(this.insectFromInfo, "pageIndex", 1);
                this.getList()
            },
            toReset(){
                this.insectFromInfo.name = ''
                this.$set(this.insectFromInfo, "pageIndex", 1);
                this.getList()
            },
            // 分页
            handleCurrentChange (page) {
                this.$set(this.insectFromInfo, "pageIndex", page);
                this.getList()
            },
            toCheck(item){
                if(this.checkArr.includes(item.id)){
                    this.checkArr = this.checkArr.filter(item => item.id !== item.id)
                }else{
                    this.checkArr.push(item.id)
                }
            }
            
        }
    }
</script>

<style lang="scss" scoped>
.addWarnInsect{
    display: flex;
    flex-direction: column;
        height: 500px;
      position: relative;
    .warn_tools_box{
        height: 40px;

        & > span{
            font-size: 26px;
        }
        .search_input{
            width: 200px;
            margin-right: 10px;
        }
    }
    .pages_box {
            text-align: center;
            position: absolute;
            bottom: -40px;
            width: 100%;
    }
    .warn_content_box{
        
        margin-top: 10px;
        padding-right: 10px;
        flex: 1;
        min-height: 0;
        display: grid;
        grid-template-columns: repeat(auto-fit, 162px);
        grid-template-rows: 40px;
        grid-auto-rows: 40px;
        grid-gap: 10px;
        color: #fff;
        & > div {
          background: #344552;
          border-radius: 5px;
          padding: 10px;
          box-sizing: border-box;
          min-width: 0;
          .text-lable {
            margin-left: 5px;
            font-size: 13px;
            text-align: left;
            flex: 1;
          }
          & > div:first-child {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          & > div:last-child {
            font-size: 14px;
           
            box-sizing: border-box;
          }
        }
    }
}
</style>