<template>
  <div class="pictrue_main_box wh100 pr">
    <HnContent :hasData="hasData">
      <template slot="left">
        <HnMenu :menuDevType="menuDevType" @change="devChange"></HnMenu>
      </template>
      <template slot="right">
        <div class="right_content_box video-box-bg-image wh100">
          <div class="tools_box">
            <HnDateSearch v-if="devId" :isPage="true" :defaultData="0" @update="dateUpdate" />
            <div>
                <el-button type="warning" size="mini" @click="insectWarn" icon="iconfont icon-gaojing1" plain >虫情预警</el-button>
            </div>
            <!-- <template v-if="warnList && warnList.length">
                                   <warnBoard :listData ='warnList' :devId='devId'></warnBoard>
                        </template> -->
          </div>

          <div class="content_box">
            <template v-if="insectPicList.length">
              <div :class="['picture_box', 'hasAnalysis']">
                <div class="picture_content_box">
                  <div :element-loading-text="loadingText" element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(40, 53, 63, 0.3)" v-loading="picListLoading"
                    :class="['bigImg_box', 'ctr_box_border', 'pr']">
                    <template v-if="picInfo">
                      <!-- <el-image
                                                    style="width: 100%;height: 100%"
                                                    :src="picInfo.imgUrl"
                                                    class="image"
                                                    fit="cover">
                                                    <div slot="error" class="image-slot" style="background: #263844">
                                                        <i class="el-icon-picture-outline"></i>
                                                    </div>
                                                </el-image> -->
                      <div class="big_img_warp_box">
                        <canvas id="myCanvas"></canvas>
                      </div>

                      <template v-if="isUseRole('320702') && devInfo && isEnterpriseUser">
                        <div class="take_but_box active cp" @click="closePage = true">
                          <span class="iconfont icon-bianji1"></span>
                          <span>提意见</span>
                        </div>
                      </template>
                      <comments v-if="closePage" :devId="activeImgId" @colse="() => (closePage = false)" />
                      <addInsecrt v-if="addmodelShow" :imgId="picInfo.id" @success="addSuccess" @close="addmodelClose">
                      </addInsecrt>
                    </template>
                  </div>
                  <ul class="smallImg_list">
                    <li v-for="item of insectPicList" :key="item.id"
                      :class="[activeImgId == item.id ? 'ctr_box_border' : '']" @click="checkBigPic(item)">
                      <el-image style="width: 100%; height: 100%" :src="thumbnailImg(item.imgUrl)" class="image"
                        fit="cover">
                        <div slot="error" class="image-slot" style="background: #263844">
                          <i class="el-icon-picture-outline"></i>
                        </div>
                      </el-image>
                    </li>
                    <template v-for="(ele, index) of supplyBox">
                      <li class="supplyBox" :key="index + 'qwe'"></li>
                    </template>
                  </ul>
                  <div class="pages_box">
                    <el-pagination @current-change="handleCurrentChange" :current-page.sync="insectFromInfo.pageIndex"
                      :page-size="8" layout="total, prev, pager, next" class="pageInfo" background :total="picTotal">
                    </el-pagination>
                  </div>
                </div>
              </div>

              <div class="insectInfo_box ctr_box_border boxBg">
                <div class="left_info_box">
                  <div class="title_box">
                    <img :src="insectIcon" alt="" />
                    <span>虫情分析</span>
                  </div>
                  <template v-if="cdnLibActiveId">
                    <ul class="info_list">
                      <li v-for="item of insectInfoList" :key="item.libId" @click="imgAnsChange(item.libId)"
                        :class="[cdnLibActiveId == item.libId ? 'activeInsect' : '','cp']">
                        <div>
                          <span>{{ item.libName }}</span>
                          <span>{{ item.num }}只</span>
                          <span class="del_icon iconfont icon-shanchu1" @click.stop="delInsectClick(item.id)"></span>
                          <div class="but_box">百科</div>
                        </div>
                        <template v-if="cdnLibActiveId == item.libId">
                          <img :src="arrowIcon" alt="" />
                        </template>
                      </li>
                    </ul>
                  </template>
                  <template v-else>
                    <el-empty :image="noDataIcon" description="暂无分析"></el-empty>
                  </template>

                  <div class="tool_but_box">
                    <div class="add_but" @click="AiInsect" v-if="isAnalysis">AI识别</div>
                    <div class="add_but" @click="addInsect">新增</div>
                  </div>
                </div>
                <div class="right_info_box">
                  <div class="title_box">
                    <img :src="bookIcon" alt="" />
                    <span>{{ pestName || "虫情百科" }}</span>
                  </div>
                  <template v-if="pestInfo">
                    <ul class="content_detail_box">
                      <li v-show="pestInfo.pestHabit">
                        <p class="baseColor">生活习性</p>
                        <p>{{ pestInfo.pestHabit }}</p>
                      </li>
                      <li v-show="pestInfo.pestFeature">
                        <p class="baseColor">害虫形态特征</p>
                        <p>{{ pestInfo.pestFeature }}</p>
                      </li>
                      <li v-show="pestInfo.control">
                        <p class="baseColor">防治措施</p>
                        <p>{{ pestInfo.control }}</p>
                      </li>
                    </ul>
                  </template>
                  <template v-else>
                    <el-empty :image="noDataIcon" description="暂无百科"></el-empty>
                  </template>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="no_data">
                <el-empty :image="bigNoDataIcon" :image-size="220" description="暂无数据"></el-empty>
              </div>
            </template>
          </div>
        </div>
      </template>
    </HnContent>
    <HnSplitPane direction="row" v-model="isSplit" :min="15" :max="60" :triggerLength="20"
    :paneLengthPercent.sync="paneLengthPercent">

       <setWarnInsect @splitColse="isSplit = false"></setWarnInsect>
    </HnSplitPane>
  </div>
</template>

<script>
import { completionRow } from "@/libs/util";
import comments from "./components/comments.vue";
import menuFun from "@/mixins/menuFun";
import addInsecrt from "./components/addInsect.vue";
import warnBoard from "@/components/localComponent/board.vue";
import canvasTip from "@/libs/canvasTip.js";
import setWarnInsect from "./components/setWarnInsect.vue";
export default {
  components: {
    comments,
    addInsecrt,
    warnBoard,
    setWarnInsect 
  },
  mixins: [menuFun],
  data () {
    return {
      isSplit: false,
      paneLengthPercent: 15,
      insectIcon: require("@/assets/imgs/insect/default/insect_icon.png"),
      arrowIcon: require("@/assets/imgs/insect/default/arrow_right.png"),
      bookIcon: require("@/assets/imgs/insect/default/book_icon.png"),
      noDataIcon: require("@/assets/imgs/insect/default/nodata.png"),
      bigNoDataIcon: require("@/assets/imgs/insect/default/nodata_big.png"),
      insectPicList: [],
      insectInfoList: [],
      menus: [],
      closePage: false,
      insectFromInfo: {
        insectId: "",
        pageSize: 8,
        pageIndex: 1,
        timeType: 0,
        sTime: "",
        eTime: "",
      },
      loadingText: '加载中',
      warnList: [],
      picTotal: 0,
      picList: [],
      picListLoading: false,
      isAnalysis: true,
      devInfoList: [],
      devInfo: null,
      picInfo: null,
      dialogVisible: true,
      menuDevType: ["虫情设备"],
      cdnLibActiveId: "",
      addmodelShow: false,
      activeImgId: "", // 激活图片
      pestInfo: null,
      pestName: "",
      devId: "",
      canvasTip: null,
    };
  },
  computed: {
    supplyBox () {
      const nub = this.isAnalysis ? 8 : 8;
      let supplyArr = completionRow(this.insectPicList, nub);
      return supplyArr || [];
    },
    isPageShow () {
      return this.picList.length > 14 ? true : false;
    },
    isEnterpriseUser () {
      return this.$store.state.user?.adminType == 2 ? true : false;
    },
  },
  watch: {
    cdnLibActiveId (val) {
      if (val) {
        this.insectInfoList.some((item) => {
          if (item.libId == val) {
            this.SysPestControlIotDetail(item.libName);
            return true;
          }
        });
      } else {
        this.pestInfo = null;
        this.pestName = "";
      }
    },
  },
  mounted () {
    this.canvasTip = new canvasTip({
      height: 528,
      el: "myCanvas",
      drawWidth: 930,
    });
  },
  methods: {
    insectWarn(){
        this.isSplit = true
    },
    // 删除虫情分析
    delInsectClick (id) {
      this.$confirm("是否确定删除当前虫情分析", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$api.DelIotInsectImgData({
            id: id
          }).then((res) => {
            if (res) {
              this.addSuccess()
              this.$msg.success("删除成功");
            }
          })

        })
        .catch(() => { });
    },

    // 压缩图片
    thumbnailImg (str) {
      var index = str.lastIndexOf("/");

      let startStr = str.substring(0, index);

      let endStr = str.substring(index, str.length);
      return `${startStr}/thumb${endStr}`; ///thumbnail
    },
    // 设备切换
    devChange (infos) {
      let data = infos[0];
      this.insectFromInfo.pageIndex = 1;
      if (data) {
        let { devId } = data;
        let devInfo = this.getDevInfo(infos);
        this.devId = devId;
        if (devInfo) {
          this.isAnalysis = devInfo.isAnalysis == 1 ? true : false;

          this.devInfo = devInfo;
        }
        this.$set(this.insectFromInfo, "insectId", devId);
        this.reqIotDevInsectImgPageList();
        // this.LogInsectLampAlarmContentList(devId)
        // this.reqIotDevInsectStateDetail(devId)
      }
    },
    imgAnsChange (id) {
      this.cdnLibActiveId = id;
    },
    addmodelClose () {
      this.addmodelShow = false;
    },
    addInsect () {
      this.addmodelShow = true;
    },
    async AiInsect () {
      this.loadingText = '识别中'
      this.picListLoading = true;
      try {
        const res = await this.$api.IotInsectImgDataAnalysis({ imgId: this.activeImgId });

        if (res) {
          this.insectPicList.some((item) => {
            if (item.id == this.activeImgId) {
              item.ansContent = res;
              this.canvasTip.initData({
                imgUrl: item.imgUrl,
                data: item.ansContent ? JSON.parse(item.ansContent) : [],
                onImgLoading: () => {
                  this.picListLoading = false;
                  this.loadingText = '加载中'
                  this.$msg.success("识别成功");
                },
              });
              return true;
            }
          });
          this.reqIotInsectImgDataList();
        } else {
          this.$msg.error("识别失败");
          setTimeout(() => {
            this.picListLoading = false;
            this.loadingText = '加载中'
          }, 500);
        }
      } catch {
        setTimeout(() => {
          this.picListLoading = false;
          this.loadingText = '加载中'
        }, 500);
      }
    },
    // 获取告警内容
    //   async LogInsectLampAlarmContentList(id){
    //       const res = await this.$api.LogInsectAlarmContentList({id})

    //       this.warnList = res
    //   },
    //获取虫情图片
    async reqIotDevInsectImgPageList () {
      this.picListLoading = true;
      try {
        const res = await this.$api.IotInsectImgsPageList(this.insectFromInfo);
        let list = res?.list;
        if (list && list.length) {
          this.picTotal = res.totalCount;
          this.insectPicList = list;
          this.picInfo = list[0];
          this.activeImgId = list[0].id;
          this.$nextTick(() => {
            this.canvasTip.initData({
              imgUrl: this.picInfo.imgUrl,
              data: this.picInfo.ansContent ? JSON.parse(this.picInfo.ansContent) : [],
              onImgLoading: () => {
                this.picListLoading = false;
              },
            });
          });

          // if(this.isAnalysis) {}

          this.reqIotInsectImgDataList();
        } else {
          this.picTotal = 0;
          this.insectPicList = [];
          this.activeImgId = "";
          this.picInfo = "";
          this.canvasTip.distroy();
          setTimeout(() => {
            this.picListLoading = false;
          }, 500);
        }
      } catch {
        setTimeout(() => {
          this.picListLoading = false;
        }, 500);
      }
    },
    // 获取虫情图片分析详情
    async reqIotInsectImgDataList (loading = false) {
      if (loading) this.picListLoading = true;
      try {
        const res = await this.$api.IotInsectImgDataList({ imgId: this.activeImgId });
        if (loading)
          setTimeout(() => {
            this.picListLoading = false;
          }, 500);
        this.insectInfoList = res && res.length ? res : [];
        this.cdnLibActiveId = this.insectInfoList.length ? this.insectInfoList[0].libId : "";
      } catch {
        if (loading)
          setTimeout(() => {
            this.picListLoading = false;
          }, 500);
      }
    },
    // 获取百科详情
    async SysPestControlIotDetail (name) {
      const res = await this.$api.SysPestControlIotDetail({ name });
      this.pestInfo = res?.errcode == 0 ? null : res;
      this.pestName = name;
    },
    addSuccess () {
      this.reqIotInsectImgDataList();
    },
    dateUpdate (data) {
      Object.assign(this.insectFromInfo, data);
      this.reqIotDevInsectImgPageList();
    },

    //查看大图
    checkBigPic (item) {
      this.picInfo = item;
      this.activeImgId = item.id;
      this.picListLoading = true;
      this.canvasTip.initData({
        imgUrl: this.picInfo.imgUrl,
        data: this.picInfo.ansContent ? JSON.parse(this.picInfo.ansContent) : [],
        onImgLoading: () => {
          this.picListLoading = false;
        },
      });
      this.reqIotInsectImgDataList();
    },
    // 分页
    handleCurrentChange (page) {
      this.$set(this.insectFromInfo, "pageIndex", page);
      this.reqIotDevInsectImgPageList();
    },
    historyData () {
      this.$router.push({
        path: "/historyInsect",
        query: {
          devId: this.devInfo.devId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.pictrue_main_box {
  .right_content_box {
    border-radius: 8px;
    box-sizing: border-box;
    padding: 40px 20px 20px 40px;

    .tools_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding-right: 20px;

      &>div:first-child {
        display: flex;
        align-items: center;

        .date_picker_box {
          margin-right: 15px;
        }

        ul.dateType_box {
          display: flex;
          //    width: 214px;
          height: 34px;
          line-height: 34px;
          font-size: 12px;
          color: #fff;
          border-radius: 17px;

          &>li {
            padding: 0 28px;
            border-radius: 17px;
          }

          &>li:not(:last-child) {
            margin-right: 8px;
          }
        }
      }
    }

    .content_box {
      height: calc(100% - 36px);
      width: 100%;
      display: flex;
      background-image: url("../../../assets/imgs/control/default/decoration_bg.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 50% 50%;
      padding: 20px 0;
      box-sizing: border-box;

      .picture_box {
        height: 100%;
        display: flex;

        .picture_content_box {
          flex: 1;
          min-width: 0;
          .bigImg_box {
            width: 100%;
            height: 530px;
            box-sizing: border-box;
            border-radius: 10px;

            .image {
              border-radius: 10px;
            }
          }

          .smallImg_list {
            width: 100%;
            height: 64px;
            display: flex;
            justify-content: space-between;
            margin: 20px 0;

            &>li {
              width: 108px;
              height: 64px;
              box-sizing: border-box;
              border: 1px solid transparent;
              transition: border 0.3s;
              border-radius: 10px;

              .image {
                border-radius: 10px;
              }
            }

            .supplyBox {
              width: 98px;
              height: 64px;
              background-color: transparent !important;
            }
          }

          .el-image ::v-deep .image-slot {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
          }

          .pages_box {
            text-align: center;
          }
        }
      }

      .hasAnalysis {
        width: 940px;
        flex-shrink: 0;
      }

      .insectInfo_box {
        flex: 1;
        height: 100%;
        margin: 0 20px;
        border-radius: 10px;
        padding: 20px 20px 20px 0;
        display: flex;
        box-sizing: border-box;
        background: #23313c;

        &>div {
          flex: 1;
          height: 100%;
        }

        .left_info_box {

          // width: 52%;
          .title_box {
            display: flex;
            align-items: center;
            font-size: 16px;
            margin-left: 15px;

            img {
              width: 29px;
              height: 27px;
              margin-right: 8px;
            }
          }

          .info_list {
            padding: 20px 0;
            max-height: calc(100% - 89px);
            overflow-y: auto;
            overflow-x: hidden;

            &>li {
              width: 100%;
              height: 40px;
              padding: 0 25px;
              box-sizing: border-box;

              font-size: 13px;

              &>div {
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                &>span:nth-child(1) {
                  flex: 3;
                }

                &>span:nth-child(2) {
                  flex: 2;
                }

                .del_icon {
                  cursor: pointer;
                  margin-right: 10px;
                  font-size: 18px;
                }

                .but_box {
                  cursor: pointer;
                  width: 50px;
                  background-color: rgba(var(--sys-color-rgb-primary), 0.3);
                  height: 22px;
                  line-height: 22px;
                  text-align: center;
                  border-radius: 11px;
                  font-size: 12px;
                }
              }
            }

            &>li:nth-child(2n-1) {
              background: url("../../../assets/imgs/insect/default/frame.png") no-repeat;
              background-size: 100% 100%;
            }

            .activeInsect {
              position: relative;

              img {
                position: absolute;
                top: 10px;
                right: 2px;
                width: 22px;
                height: 17px;
              }

              .but_box {
                background-color: var(--el-color-primary) !important;
              }
            }
          }

          .add_but {
            width: 80px;
            height: 30px;
            background-color: rgba(var(--sys-color-rgb-primary), 0.3);
            border-radius: 10px;
            border: solid 1px var(--el-color-primary);
            font-size: 14px;
            line-height: 30px;
            text-align: center;
            margin: 0 auto;
          }

          .add_but:hover {
            cursor: pointer;
            color: var(--el-color-primary);
          }

          .tool_but_box {
            display: flex;
            justify-content: space-between;
          }
        }

        .right_info_box {
          //    width: 48%;
          background: #263844;
          border-radius: 10px;

          .title_box {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            position: relative;
            padding: 15px;

            img {
              width: 29px;
              height: 27px;
              margin-right: 8px;
            }
          }

          .title_box::after {
            width: 100%;
            height: 1px;
            display: block;
            background: linear-gradient(to right, rgba(var(--sys-color-rgb-primary), 0) 0%, var(--el-color-primary) 50%, rgba(var(--sys-color-rgb-primary), 0) 100%);
            position: absolute;
            left: 0;
            bottom: 0;
            content: "";
          }

          .content_detail_box {
            width: 100%;
            height: calc(100% - 65px);
            overflow-y: auto;
            padding: 0 12px;
            font-size: 12px;
            box-sizing: border-box;

            li {
              &>p:first-child {
                font-size: 15px;
                font-weight: bold;
                margin-top: 18px;
              }

              &>p:last-child {
                margin-top: 8px;
                text-indent: 20px;
                line-height: 18px;
              }
            }
          }
        }
      }

      .no_data {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }

  .big_img_warp_box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #101010;
    border-radius: 10px;
  }

  .take_but_box {
    width: 100px;
    height: 36px;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0 8px 0 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    z-index: 100;

    &>span:first-child {
      font-size: 18px;
      margin-right: 5px;
      margin-left: 10px;
    }
  }
}
</style>
