import config from '@/config'
class createCanvas {
    maxScale = 8;
    minScale = 1;
    scaleStep = 0.2;
    scale = 1;
    preScale = 1;
    mousePosition = { x: 0, y: 0 }; // 记录鼠标滚轮点击时的坐标位置
    offset = { x: 0, y: 0 }; // 拖动偏移
    curOffset = { x: 0, y: 0 }; // 记录上一次的偏移量
    x = 0; // 记录鼠标点击Canvas时的横坐标
    y = 0; // 记录鼠标点击Canvas时的纵坐标
    constructor (options) {
        
        let  { height,el,drawWidth,drawColor='#101010' } = options
        this.$el = el
        this.initHeight = height || '70%'
        this.drawWidth = drawWidth
        this.drawColor = drawColor
        this.onMousedown = this.onMousedown.bind(this);
        this.onMousemove = this.onMousemove.bind(this);
        this.onMouseup = this.onMouseup.bind(this);
        this.onMousewheel = this.onMousewheel.bind(this);
        
        
    }
    initData(options){
        const image = new Image()
        // 2 引入图片
        image.src = options ?.imgUrl
        this.data = options ?.data
        this.image = image
        if(this.myCanvas) this.clear()
        image.onload = () => {
            if(options ?.onImgLoading && typeof options.onImgLoading == 'function')options.onImgLoading(true)
            this.hanldeSize()
           if(this.myCanvas){
            this.myCanvas.width = this.width
            this.myCanvas.height = this.height
            this.draw()
           }else this.initCanvas()
        }
        image.onerror = () => {
            if(options ?.onImgLoading && typeof options.onImgLoading == 'function')options.onImgLoading(false)
        }
    }
    hanldeSize(){
        let rH = 0
        const imgW = this.image.width
        const imgH = this.image.height
        let height = this.initHeight
       if(typeof height == 'number'){
          rH = height
       }else if(height.indexOf('px') != -1){
          rH = parseInt(height.replace('px',''))
       }else if(height.indexOf('%') != -1){
          const clientHeight = document.documentElement.clientHeight
          const nub = parseInt(height.replace('%','')) / 100
         
          rH = clientHeight*nub
       } 

       let width = (imgW * rH)/imgH
       this.width = this.drawWidth && this.drawWidth > width ? this.drawWidth : width
       this.imgW = width
       this.height = rH
       this.ratio = rH/imgH
        
    }
    initCanvas(){
     
        const myCanvas = document.getElementById(this.$el)
        myCanvas.width = this.width
        myCanvas.height = this.height
        this.myCanvas = myCanvas
        this.myCanvas.addEventListener('mousewheel', this.onMousewheel);
        this.myCanvas.addEventListener('mousedown', this.onMousedown);
        this.ctx = myCanvas.getContext('2d') 
        this.draw()
        

    }
    resetCanvas(){

    }
    draw(){
        this.positionX = (this.width - this.imgW) / 2
        this.setRect()
        this.ctx.drawImage(this.image, this.positionX, 0,this.imgW,this.height)
        if(this.data && this.data.length){
            this.data.map((item,i) => {
                
                const colorLength = config.insectColor.length
                let colorIndex = 0
                if(i < colorLength) colorIndex = i
                else{
                    colorIndex = i%colorLength
                } 
                if(item.locations && item.locations.length){
                    item.locations.map(info => {
                        let positionInfo = this.getPointPosition(info)
                        this.setPoint(positionInfo,config.insectColor[colorIndex])
                        this.setText(positionInfo,item.name)
                    })
                }
                
            })
        }
        
    }
    setRect(){
        this.ctx.beginPath()
        this.ctx.fillStyle = this.drawColor
        this.ctx.fillRect(0,0,this.width,this.height)
        this.ctx.stroke(); 
    }
    setPoint(positionInfo,color) {
        const { x,y,w,h} = positionInfo
        this.ctx.beginPath()
        this.ctx.strokeStyle = color
        this.ctx.strokeRect(x,y,w,h)
        this.ctx.stroke();
    }
    setText(positionInfo,name){
    const { x,y,w,h} = positionInfo
    this.ctx.font = "12px Arial"
    this.ctx.fillStyle = "#fff"
    this.ctx.textAlign = "center"
    this.ctx.textBaseline = 'middle'
    this.ctx.fillText(name, (x + (w/2)), (y-6))

    }
    getPointPosition(location){ 
      
       let {left,top,height,width } = location
       return {
         x : this.ratio * left + this.positionX,
         y : this.ratio * top,
         w : this.ratio * width,
         h : this.ratio * height
       }

    }
    onMousewheel(e) {
    
        e.preventDefault();
        this.mousePosition.x = e.offsetX; // 记录当前鼠标点击的横坐标
        this.mousePosition.y = e.offsetY; // 记录当前鼠标点击的纵坐标
        if(e.wheelDelta > 0){
            // 放大
            this.scale = parseFloat((this.scaleStep + this.scale).toFixed(2)); // 解决小数点运算丢失精度的问题
            if (this.scale > this.maxScale) {
                this.scale = this.maxScale;
                return;
            }
        }else{
            // 缩小
            this.scale = parseFloat((this.scale - this.scaleStep).toFixed(2)); // 解决小数点运算丢失精度的问题
            if (this.scale < this.minScale) {
                this.scale = this.minScale;
                return;
            }
        }
        this.zoom();

    }
    zoom() {
        this.offset.x = this.mousePosition.x - (this.mousePosition.x - this.offset.x) * this.scale / this.preScale;
        this.offset.y = this.mousePosition.y - (this.mousePosition.y - this.offset.y) * this.scale / this.preScale;

        this.paint(this.ctx);
        this.preScale = this.scale;
        this.curOffset.x = this.offset.x;
        this.curOffset.y = this.offset.y;
    }
    onMousedown(e) {
        if (e.button === 0) {
        // 鼠标左键
            this.x = e.x;
            this.y = e.y
            window.addEventListener('mousemove', this.onMousemove);
            window.addEventListener('mouseup', this.onMouseup)
        }
    }
    onMousemove(e) {
        this.offset.x = this.curOffset.x + (e.x - this.x);
        this.offset.y = this.curOffset.y + (e.y - this.y);

        this.paint();
    }
    onMouseup() {
        this.curOffset.x = this.offset.x;
        this.curOffset.y = this.offset.y;
        window.removeEventListener('mousemove', this.onMousemove);
        window.removeEventListener('mouseup', this.onMouseup);
    }
    clear() {
        this.myCanvas.width = this.width;
    }
    // 重置
    reset() {
        this.clear();
        this.scale = 1; // 当前缩放
        this.preScale = 1; // 上一次缩放
        this.offset = { x: 0, y: 0 }; // 拖动偏移
        this.curOffset = { x: 0, y: 0 }; // 当前偏移
        this.mousePosition = { x: 0, y: 0 };
        this.draw();
    }
    paint() {
        this.clear();
        this.ctx.translate(this.offset.x, this.offset.y);
        this.ctx.scale(this.scale, this.scale);
        this.draw()
    }
    distroy(){
        this.myCanvas = null 
        window.removeEventListener('mousewheel', this.onMousewheel);
    }

}
export default createCanvas