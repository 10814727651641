<template>
    <div class="setWarnInsect">
        <div class="warn_tools_box">
            <div>
                <el-input placeholder="请输入虫害名" v-model="search" class="search_input" size="small"></el-input>
                <el-button type="primary" @click="toSearch" size="small">搜索</el-button>
                <el-button type="warning" @click="toReset" size="small" style="color: #fff;">重置</el-button>
                <el-button type="primary" @click="toAdd" size="small">添加虫库</el-button>
            </div>
            <span class="el-icon-close cp" @click="splitColse"></span>
        </div>
        <div class="warn_content_box scrollbarMiniGary">
            <div v-for="item in insectInfo" :key="item.id">
                <div>
                    <el-checkbox v-model="item.isEnable" @change="toCheck(item)"></el-checkbox>
                    <el-popover
                    placement="right"
                    width="400"
                    trigger="click">
                
                       <el-image :src="item.imgs">
                        <div slot="error" class="image-slot" >
                            <i class="el-icon-picture-outline"></i>
                        </div>
                        </el-image>
                 
                    <span class="single-ellipsis text-lable cp" slot="reference">{{ item.insectLibName }}</span>
                    </el-popover>
                    
                  
                </div>
                <div class="font-12 set_box">
                    <span>每日虫情预警值 </span>
                    <el-input-number class="nub_input" v-model="item.alarmMax" size="mini" style="width: 60px;" :controls="false" @change="handleChange" :min="1" :max="10000"></el-input-number>
                </div>
           </div>
           
        </div>
    </div>
</template>

<script>
    import addWarnInsect from './addWarnInsect.vue'
    import debounce from '@/libs/debounce'
    export default {
        data(){
            return {
                search: '',
                insectInfo: []
                 
                    
               
            }
        },
        created(){
            this.getList()
        },
        methods:{
            async getList(){
                let res = await this.$api.IotInsectAlarmList({name: this.search})
                console.log(res)
                this.insectInfo = res
            },
            handleChange(item){
               if(item.alarmMax > 0 ) debounce(this.setAlarm(item), 500)
            },
            async setAlarm(item){
                let res = await this.$api.SetIotInsectAlarm([
                    {
                        insectLibId: item.insectLibId,
                        alarmMax: item.alarmMax
                    }
                ])
                this.$msg.success("设置成功");
            },
            toCheck(item){
                const text = !item.isEnable ? '不配置虫情预警，无法第一时间收到虫情预警，是否确定' : '是否确定启用当前虫情预警'
                this.$confirm(text, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        console.log(item)
                    this.$api.SetIotInsectAlarmIsEnable({
                        id: item.insectLibId
                    }).then((res) => {
                        if (res) {
                      
                          this.$msg.success("设置成功");
                         
                        }
                    })

                    })
                    .catch(() => {
                        item.isEnable = !item.isEnable
                     });
            },
            toSearch(){
                this.getList()
            },
            toReset(){
                this.search = ''
                this.getList()
            },
            toAdd(){
                
                this.showDialog(addWarnInsect,{},{title: '添加预警虫库',width: '910px'},() => {
                    this.getList()
                })
            },
            splitColse(){
                this.$emit('splitColse')
            }
            
        }
    }
</script>

<style lang="scss" scoped>

.setWarnInsect{
    display: flex;
    flex-direction: column;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
    .warn_tools_box{
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        & > span{
            font-size: 26px;
        }
        .search_input{
            width: 200px;
            margin-right: 10px;
        }
    }
    .warn_content_box{
        
        margin-top: 10px;
        padding-right: 10px;
        flex: 1;
        min-height: 0;
        display: grid;
        grid-template-columns: repeat(auto-fit, 200px);
        grid-template-rows: 70px;
        grid-auto-rows: 70px;
        grid-gap: 10px;
        & > div {
          background: #344552;
          border-radius: 5px;
          padding: 10px;
          box-sizing: border-box;
          min-width: 0;
          .text-lable {
            margin-left: 5px;
            font-size: 13px;
            text-align: left;
            flex: 1;
          }
          & > div:first-child {
            display: flex;
     
            align-items: center;
          }
          & > div:last-child {
            font-size: 14px;
            padding-left: 20px;
            box-sizing: border-box;
          }
       
        }
        .set_box{
           display: flex;
           align-items: center;   
        }
    }
    .nub_input{
        margin-left: 10px;
    }
    .nub_input ::v-deep .el-input__inner{
          background: #15232D !important;
          padding-left: 5px;
          padding-right: 5px;
    }
}
</style>